import { useState } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from "axios";
import Loading from "components/Loadings/Loading";
import dynaskillsLogo from '../../assets/img/dynaskillsuprising.png'
import base64Encoder from "utils/base64Encoder";
import { useTranslation } from "react-i18next";
import PasswordChecklist from "react-password-checklist"



export default function Register() {
  const { t, i18n } = useTranslation('site');
  const history = useHistory()
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showPasswordValidation, setShowPasswordValidation] = useState(false);

  // const search = useLocation().search;
  // const company = new URLSearchParams(search).get("company");
  
  async function registerUser(e) {
    e.preventDefault();
    setErrorMessage("");
    setShowLoader(true);
    //const url = 'https://ukranian-sunrise-server.herokuapp.com/api/users/register';
    const url = process.env.REACT_APP_BACKEND_DYNASKILLS+'/users/register';
    await axios.post(url, {
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        username,
        email,
        password,
        company: "premium"
      }
    }).then(response => {
      const data = response.data;
      if (data.status === 'ok') {
        // history.push('/auth/login');
        setShowSuccess(true);
        setTimeout(() => {
          history.push('/auth/login')
        }, 3000);
        
      }
    }).catch(err => {
      console.log(err.response);
      setErrorMessage(t(err?.response?.data?.error));
    }).finally(() => {
      setShowLoader(false);
    });

  }

  const changeLanguageHandler = (e) =>
  {
    i18n.changeLanguage(e.target.value)
  }

  const handlePassword = (e) => {
    setShowPasswordValidation(true)
    setPassword(e.target.value);
  }

  const handleConfirmationPassword = (e) => {
    setShowPasswordValidation(true)
    setConfirmationPassword(e.target.value);
  }

  
  return (
    <>
      
      <div className="container mx-auto px-4 h-full ">
        <div className="flex justify-between w-full">
          <h2 className="blueTextDark text-lg font-bold relative">
          {t('new_candidate')}
          </h2>
          <div className="blueTextDark text-lg text-center font-bold relative">
          {t('already_an_account')}<br/>
            <Link to="/auth/login" className="text-center" style={{textDecoration: 'underline'}} >
               {t('sign_in_btn')}
            </Link>
          </div>
        </div>
        <div className="relative flex content-center items-center justify-center h-full">
        <div className="absolute right-0 top-28">
            <select className="ml-3  placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline font-semibold" onChange={e=>changeLanguageHandler(e)} value={i18n.language.substring(0, 2)}>
                    <option value="de">{t('german')}</option>
                    <option value="en">{t('english')}</option>
                    <option value="uk">{t('ukranian')}</option>
                    <option value="fr">{t('french')}</option>
                    <option value="it">{t('italian')}</option>
                    <option value="ar">{t('arabic')}</option>
                    <option value="pt">{t('portuguese')}</option>
                    <option value="ur">{t('urdu')}</option>
                    <option value="zh">{t('chinese')}</option>
              </select>
            </div>
          <div className="w-full lg:w-5/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 border-0">
              
              <div className="flex justify-center mb-10">
                <img src={dynaskillsLogo} alt="Ukranian Sunrise" className="ml-10" style={{width:"280px", height:"auto"}}/>
              </div>
              <div className="text-center mb-5">
                <h6 className="blueTextDark text-2xl font-bold">
                {t('sign_up')}
                </h6>
              </div> 

              {showSuccess && <div className="bg-green-400 mb-5 px-4 text-center py-5 rounded text-white">
                {t('create_account_success')}
              </div>}
              
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                
                <form className="flex flex-wrap " onSubmit={registerUser}>
                  <div className="relative mb-3 w-full">
                    <label
                      className="block uppercase blueTextDark text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      {t('username')}
                    </label>
                    <input
                      type="text"
                      className="bg-white border-0  px-3 py-3 placeholder-blueGray-300 blueTextDark  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                    />
                  </div>
                  
                  
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase blueTextDark text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      {t('email')}
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 blueTextDark bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase blueTextDark text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      {t('password')}
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 blueTextDark bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) => handlePassword(e)}
                      onBlur={() => setShowPasswordValidation(false)}
                      value={password}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase blueTextDark text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      {t('retype_password')}
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 blueTextDark bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) => handleConfirmationPassword(e)}
                      onBlur={() => setShowPasswordValidation(false)}
                      value={confirmationPassword}
                    />
                  </div>

                  {showPasswordValidation && <div className="pb-5 ">
                    <PasswordChecklist
                      rules={["specialChar", "lowercase", "number", "capital", "match"]}
                      minLength={4}
                      iconSize={10}
                      value={password}
                      valueAgain={confirmationPassword}
                      messages={{
                        number: t('password_number'),
                        specialChar: t('password_specialChar'),
                        lowercase: t('password_lowercase'),
                        capital: t('password_capital'),
                        match: t('password_match')
                      }}
                    />
                  </div>}

                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded blueTextDark ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold blueTextDark">
                        <a
                          className="font-bold"
                          href="https://dynaskills-dynajobs.com/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('i_agree_with_the_privacy_policy')}
                        
                        </a>
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6  w-full ">
                    <button
                      className="blueDarkBg text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      
                      { showLoader ? <>{t('loading')} <Loading width="30" height="30" className="inline-flex" color="#fff" /></> : t('create_account')}
                    </button>
                    <p className="text-red-500">{errorMessage}</p>
                    {/* <span className="text-left text-sm font-semibold blueTextDark">
                      <a
                        href="/auth/login"
                      >
                        Sign instead
                      </a>
                    </span> */}
                    
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
